/* eslint-disable no-mixed-operators */
import React, { useState }  from 'react';
import DeckGL from '@deck.gl/react';
import { ScatterplotLayer } from '@deck.gl/layers';
import ReactMapGL, { NavigationControl, _MapContext as MapContext }  from "react-map-gl";
import nearestColor from 'nearest-color';
import ReactAudioPlayer from 'react-audio-player'
import * as d3 from 'd3';
import _ from 'lodash'
import { Drawer, Row, Button } from 'antd'

import noise from './assets/ES_CityStreet9.mp3'

import Tooltip from './Tooltip';
import Legend from './Legend'
import './App.css';

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoicGZjcm91c3NlIiwiYSI6ImNrZmJrY3podDByY2MzMm84eXpyNDRnZXAifQ.8PKIfZ5Ac9BtQ0VnXInpbw';
const MAPBOX_STYLE = 'mapbox://styles/pfcrousse/ckfa0brtk6yv819mwtehv4s3v';

// Viewport settings
const INITIAL_VIEW_STATE = {
  longitude: 4.3549,//4.4026,
  latitude: 50.8412,//50.8403,
  zoom: 11,
  pitch: 0,
  bearing: 0
};

const steps = [
  {
    value: 40,
    label: '< 45',
    color: '#92cd66'
  },
  {
    value: 45,
    label: '45',
    color: '#f0ef42'
  },
  {
    value: 50,
    label: '50',
    color: '#f1de00'
  },
  {
    value: 55,
    label: '55',
    color: '#eea036'
  },
  {
    value: 60,
    label: '60',
    color: '#d83f36'
  },
  {
    value: 65,
    label: '65',
    color: '#4d7db3'
  },
  {
    value: 70,
    label: '70',
    color: '#ad639b'
  },
  {
    value: 75,
    label: '75',
    color: '#7d7d7d'
  },
]

function App() {
  let mapRef;
  const [tooltip, setTooltip] = useState(null)
  const [volume, setVolume] = useState(0)
  const [muted, setMuted] = useState(true)
  const [openDrawer, setOpenDrawer] = useState(true)
  const [layers, setLayers] = useState(null)

  const playNoise = () => {
    const audio = document.getElementById("noise-audio");
    audio.play();
  }

  const onMouseMove = (e) => {
    //https://stackoverflow.com/questions/57862383/how-to-get-the-color-of-pixels-on-mapbox
    if(mapRef) {
      const canvas = mapRef.getMap().getCanvas();
      const gl = canvas.getContext('webgl') || canvas.getContext('webgl2');
      if (gl) {
        const { pixel: [x, y], coordinate } = e;
        const devicePixelRatio = window.devicePixelRatio
        const data = new Uint8Array(4);
        const canvasX = x * devicePixelRatio;
        const canvasY = canvas.height - (y * devicePixelRatio);
        gl.readPixels(canvasX, canvasY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data);
        const [r, g, b, a] = data;
        const color = `rgba(${r}, ${g}, ${b}, ${a})`;
        
        const colors = _(steps)
          .keyBy('value')
          .mapValues('color')
          .value()
        const colorHex = `#${rgba2hex(color)}`
        const value = +nearestColor.from(colors)(colorHex).name;

        setTooltip(x === -1 && y === -1 ? null : { x, y, color, value })

        const volumeScale = d3.scaleLinear()
          .domain(d3.extent(steps, d => d.value))
          .range([0.1, 1])

        setVolume(x === -1 && y === -1 ? 0 : volumeScale(value))
        
        computeLayers(x === -1 && y === -1 ? null : coordinate, [r, g, b])
      }
    }
  }

  const computeLayers = (coordinates, color) => {
    if(!coordinates) return null
    const layer = new ScatterplotLayer({
      id: 'scatterplot-layer',
      data: [coordinates],
      pickable: false,
      opacity: 0.8,
      stroked: true,
      filled: true,
      radiusScale: 6,
      radiusMinPixels: 8,
      radiusMaxPixels: 8,
      lineWidthMinPixels: 3,
      getPosition: d => d,
      getRadius: d => 8,
      getFillColor: d => color,
      getLineColor: d => [255, 255, 255]
    });

    setLayers([layer]);
  }

  const rgba2hex = (orig) => {
    let rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
    let hex = rgb ?
      (rgb[1] | 1 << 8).toString(16).slice(1) +
      (rgb[2] | 1 << 8).toString(16).slice(1) +
      (rgb[3] | 1 << 8).toString(16).slice(1) : orig;
  
    return hex;
  }

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <DeckGL
        controller={true}
        layers={layers}
        initialViewState={INITIAL_VIEW_STATE}
        onHover={onMouseMove}
        getCursor={() => "crosshair"}
        ContextProvider={MapContext.Provider}
        onDragStart={() => setTooltip({ ...tooltip, x: -1000, y: -1000})}
      >
        <ReactMapGL
          reuseMaps
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          preventStyleDiffing
          mapStyle={MAPBOX_STYLE}
          ref={map => {mapRef = map}}
          preserveDrawingBuffer={true}
        >
        </ReactMapGL>
        <Tooltip data={tooltip} />
        <div style={{ position: "absolute", right: 30, top: 30 }}>
          <NavigationControl />
        </div>
        <div>
          <ReactAudioPlayer
            id="noise-audio"
            src={noise}
            //controls
            autoPlay
            autoplay
            muted={muted}
            volume={volume}
            loop
          />
        </div>
      </DeckGL>
      <div style={{ position: "absolute", left: '5vw', top: '5vh', width: '100vw', pointerEvents: 'none', textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'}}>
        <div style={{ fontSize: '10vh', fontFamily: 'Playfair Display' }}>Noisy City</div>
        <div style={{ fontSize: '2vh' }}>
          <div>Audible data visualization on Brussels noise pollution.</div>
          <div>Crank up the volume and mouse over the city.</div>
        </div>
      </div>
      <div style={{ position: "absolute", left: '4vw', bottom: 0, width: '100vw' }}>
        <Legend muted={muted} setMuted={setMuted} value={tooltip && tooltip.value}/>
      </div>
      <Drawer
          title="Volume On"
          placement="bottom"
          closable={false}
          visible={openDrawer}
        >
          <p>This data visualization is an experiment aiming at making you feel and hear the noise pollution affecting Brussels.</p>
          <p>If you want to experience the sound just crank your volume up and click on the "With sound" button.</p>
          <Row type="flex" gutter={8} justify="end" style={{ marginBottom: 24 }}>
            <Button type="default" onClick={() => {setOpenDrawer(false);}}>Without sound</Button>
            <Button style={{ marginLeft: 8}} type="primary" onClick={() => {setOpenDrawer(false); setMuted(false); playNoise();}}>With sound</Button>
          </Row>  
      </Drawer>
    </div>
  );
}

export default App;
