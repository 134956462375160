import React from "react"
import { Button, Tooltip } from 'antd'
import { SoundOutlined } from '@ant-design/icons';

import GaugeChart from './charts/SoundGaugeChart/SoundGaugeChartComponent'
import logo from './assets/Jetpack-small.png'

const legendStyle = {
  position: 'absolute',
  zIndex: 1,
  background: 'white',
  fontSize: 9,
  padding: 5,
  overflow: 'hidden',
  width: 250,
  height: 330,
  boxShadow: '2px 2px 4px #ccc',
  left: 20,
  bottom: 45,
  backgroundColor: '#ffffffdf',
  borderRadius: 2000
};


function Legend({ value, muted, setMuted }) {
  return (
    <div style={legendStyle}>
      <GaugeChart value={value} />
      <div style={{ textAlign: 'center', fontSize: 12 }}>ANNUAL AVERAGE<br/> NOISE INTENSITY (dB)</div>
      <div style={{ textAlign: 'center', position: 'absolute', top: 130, width: '100%', marginLeft: -5 }}>
        <Tooltip title={muted ? 'Unmute' : 'Mute'} placement="bottom">
          <Button style={{ opacity: muted ? 0.3 : 1 }} type={muted ? 'dashed' : 'default'} shape="circle" icon={<SoundOutlined />} onClick={() => setMuted(!muted)} />
        </Tooltip>
      </div>
      <div style={{ textAlign: 'center', marginTop: 24}} >
      <div> Data source: <a href="https://environnement.brussels/thematiques/bruit/la-situation-bruxelles/cartographie-et-exposition-de-la-population" target="_blank" rel="noopener noreferrer">environnement.brussels</a></div>
        <div> Designed by <a href="https://www.linkedin.com/in/karim-douieb/" target="_blank" rel="noopener noreferrer">Karim Douieb</a></div>
        <div style={{ textAlign: 'center', marginTop: 8}}><a href="https://www.jetpack.ai" target="_blank" rel="noopener noreferrer"> <img alt="map" style={{marginLeft: 8, marginRight: 8, width: 120}} src={logo}/></a></div>
      </div>
    </div>
  )
}

export default Legend;